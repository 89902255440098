<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <h2 class="title">DISEÑO</h2>
          <p>
            Diseñamos tu casa ideal con proyectos que se adaptan a tu presupuesto.
          </p>
          <p>
            Trabajamos con un modelo en tres dimensiones, que permite entender fácilmente las 
            propuestas arquitectónicas. Vas a poder analizar e interpretar el proyecto de forma fácil 
            e intuitiva, de este mismo modo obtenemos documentación siempre actualizada y coordinada. 
          </p>
          <p>
            La comunicación es lo más importante para nosotros. En cada reunión presentamos nuestros
            avances con el fin de compartir el proceso con nuestros clientes, haciéndolos participar del mismo. 
            El resultado es un diseño a medida acorde a las necesidades. 
          </p>
          <p>
            Diseñamos obra nueva,
            interiores y remodelaciones. Realizamos presentaciones de proyectos, láminas y catálogos.
            Proyectos de viviendas unifamiliares y multifamiliares, así como de edificios de oficinas,
            locales gastronómicos y comerciales.
          </p>
        </div>
        <div class="col-lg-6 col-md-12" style="align-self: center;">
          <img src="img/conoce-mas-landing/02_LAND_DISEÑO.jpeg" alt="disenio" style="inline-size: -webkit-fill-available;">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
