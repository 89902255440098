<template>
  <div class="section section-nucleo-icons">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-md-12" style="align-self: center;">
          <img src="img/conoce-mas-landing/03_LAND_DOCUMENTACION.jpeg" alt="obra_plano" style="inline-size: -webkit-fill-available;">
        </div>
        <div class="col-lg-6 col-md-12">
          <h2 class="title">DOCUMENTACIÓN</h2>
          <p>
            Nuestra documentación es extraída de un modelo BIM desarrollado según el nivel de detalle buscado. No 
            sólo podemos abastecernos de información gráfica y coordinada sin errores, sino que nos posibilita
            la especificación y cuantificación de materiales y elementos para realizar un correcto planeamiento de
            la obra.
          </p>
          <p>
            Creemos que toda realización exitosa de una obra está basada en una documentación ejecutiva sólida 
            que la respalde y evacúe todas las posibles dudas. La utilización de software BIM nos permite construir 
            virtualmente el proyecto, con lo cual podemos anticiparnos a posibles colisiones o complicaciones 
            constructivas. Al solucionar las mismas previamente estaremos dejando vía libre al avance de la obra
            sin contratiempos.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style></style>
